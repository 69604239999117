import { FacebookIcon, InstagramIcon, YoutubeIcon } from "../../assets/Icons";
import Newsletter from "../Newsletter";
import CtaSection from "../CtaSection";
import Project from "../Project";

function Projects() {
  return (
    <main id="projects" className="">
      <section id="hero" className="hero relative">
        <div className="flex flex-col justify-center h-[50svh] bg-black bg-opacity-50 lg:h-[60svh]">
          <div className="text-[#f3f3f3] text-center space-y-3 mt-[15vh] w-[92%] md:max-w-[90vw] mx-auto md:ml-[6%]">
            <h1
              data-aos="fade-up"
              data-aos-offset="200"
              className="text-6xl -mb-1 tracking-tighter sm:text-7xl xl:text-[102px]"
            >
              Our Projects
            </h1>

            <p
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="200"
              className="text-xs max-w-md mx-auto sm:text-sm leading-snug"
            >
              We believe in a brighter tomorrow for all.
            </p>
          </div>

          <div className="absolute bottom-0 w-full">
            <div className="flex items-center justify-end gap-2 mx-4 mb-6 md:flex-col md:items-end sm:mx-8">
              <a href="https://www.instagram.com/christopherandviolet?igsh=azR5ZmhvYmlsbTRu&utm_source=qr">
                <InstagramIcon />
              </a>
              <a className="bgpink7" href="https://facebook.com">
                <FacebookIcon />
              </a>
              <a href="https://youtube.com">
                <YoutubeIcon />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-amber-50 bg-opacity-5 py-12 px-[5%] md:py-16">
        <div className="max-w-7xl mx-auto space-y-16">
          <Project />
        </div>
      </section>

      <CtaSection />

      <Newsletter />
    </main>
  );
}

export default Projects;
