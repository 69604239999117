import React, { useState } from "react";
import useNavigateAndScroll from "../lib/customHookes/useNavigateAndScroll";

import { FacebookIcon, InstagramIcon, YoutubeIcon } from "../assets/Icons";
import { DonationForm } from "./Components";

function Hero() {
  const [isDonationForm, setIsDonationForm] = useState("");

  return (
    <section id="hero" className="hero relative">
      <div className="flex flex-col justify-center h-[80svh] bg-black bg-opacity-55 text-center md:text-left">
        <div className="text-[#f3f3f3] space-y-3 mt-[6vh] w-[90%] md:max-w-[90vw] mx-auto md:ml-[6%]">
          <h1
            data-aos="fade-up"
            data-aos-offset="200"
            className="text-6xl tracking-tighter max-w-6xl sm:text-7xl xl:text-[102px]"
          >
            <span className="hidden md:inline-flex">Profound</span> Commitment <br className="" />
            To Humanity
          </h1>

          <p
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="200"
            className="pb-4 text-sm max-w-md mx-auto sm:text-base leading-snug md:mx-0"
          >
            A charity organization driven by a profound commitment to support children and young
            adults.
          </p>

          <div data-aos="fade-up" data-aos-offset="100" data-aos-delay="300">
            <button
              onClick={() => setIsDonationForm(!isDonationForm)}
              className="pry-cta-button py-6 bg-yellowx-600 uppercase text-sm shadow-md sm:max-w-[200px] sm:py-8"
            >
              Make a donation
            </button>
          </div>
        </div>

        <div className="absolute bottom-0 w-full">
          <div className="flex items-center justify-end gap-2 mx-5 mb-8 md:flex-col md:items-end sm:mx-8">
            <a
              href="https://www.instagram.com/christopherandviolet?igsh=azR5ZmhvYmlsbTRu&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              className="bgpink7"
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
              <YoutubeIcon />
            </a>
          </div>
        </div>
      </div>
      {isDonationForm && <DonationForm value={isDonationForm} setter={setIsDonationForm} />}
    </section>
  );
}

export default Hero;
