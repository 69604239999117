// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";

import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper/modules";

import { testimonialDATA } from "../assets/data";
import { TestimonialCard } from "./Components";

const Testimonials = () => {
  return (
    <section className="bg-gray-500 bg-opacity-5 py-12 text-gray-900 md:py-16">
      <div className="max-w-7xl mx-auto">
        <div className="px-4 mb-16 text-center">
          <div
            data-aos="fade-up"
            data-aos-offset="150"
            className="flex items-center justify-center gap-1"
          >
            <hr className="bg-red-900 border-none h-0.5 w-[20px]" />
            <h2 className="font-bold text-lg text-red-900">Our Success Stories</h2>
            <hr className="bg-red-900 border-none h-0.5 w-[20px]" />
          </div>
          <p
            data-aos="fade-up"
            data-aos-offset="150"
            className="text-5xl lg:text-7xl lg:leading-[1.08]"
          >
            Hear Directly from our Beneficiaries.
          </p>
        </div>

        <div data-aos="fade-up" data-aos-offset="150" className="ml-4 sm:mx-4">
          <Swiper
            modules={[Pagination, Navigation]}
            grabCursor={true}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            navigation={{
              enabled: false,
            }}
            loop={true}
            spaceBetween={10}
            slidesPerView={1.06}
            centeredSlides={false}
            style={{
              "--swiper-theme-color": "#7f1d1d",
              "--swiper-pagination-bullet-inactive-color": "#7f1d1d",
              "--swiper-pagination-bullet-inactive-opacity": "0.3",
              "--swiper-navigation-top-offset": "98%",
              "--swiper-navigation-sides-offset": "46.5%",
              "--swiper-navigation-size": "52px",
              "--swiper-navigation-bg": "#7f1d1d",
            }}
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 2,
              },
              // when window width is >= 960px
              768: {
                slidesPerView: 2.05,
              },
              // when window width is >= 1280px
              1024: {
                slidesPerView: 3,
                pagination: false,
                navigation: {
                  enabled: true,
                },
              },
            }}
          >
            {testimonialDATA.map(({ imageURL, quote, name, address }, index) => (
              <SwiperSlide className="mb-14 rounded-b-3xl shadow-sm">
                <TestimonialCard
                  index={index}
                  imageURL={imageURL}
                  quote={quote}
                  name={name}
                  address={address}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
