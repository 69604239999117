import toast from "react-hot-toast";
import emailjs from "@emailjs/browser";

import React, { useState } from "react";
import axios from "axios";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");

  //nodemailer
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   axios
  //     .post("http://localhost:3001/send-email", email)
  //     .then((response) => {
  //       if (response.data.success) {
  //         toast.success("Subscribed successfully!");
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error("Error subscribing: " + error.message);
  //     });
  // };

  const validateForm = () => {
    let newErrors = {};

    if (!email) newErrors.email = "Email is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = () => {
    const serviceId = process.env.REACT_APP_emailjs_serviceId;
    const publicKey = process.env.REACT_APP_emailjs_publicKey;
    const templateId = process.env.REACT_APP_emailjs_newsletter_templateId;

    // Send the email using EmailJS
    emailjs
      .send(
        serviceId,
        templateId,
        { to_email: email, from_name: "Christopher and Violet Foundation" },
        publicKey
      )
      .then((response) => {
        toast.dismiss();
        toast.success("Subscribed successfully!");
        setEmail("");
      })
      .catch((error) => {
        toast.error(`Error subscribing: ${error}`);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      toast.loading("Subscribing...");
      sendEmail();
    }
  };

  return (
    <section>
      <div className="px-6 py-14 bg-amber-50 bg-opacity-5 sm:py-32x">
        <div data-aos="fade-up" data-aos-offset="100">
          <div className="max-w-lg mx-auto text-gray-900">
            <div className="text-center text-sm mb-10 md:text-base">
              <p className="text-4xl mb-1 md:text-4xl">Subscribe Newsletter</p>
              <p>Stay informed with our latest developments and events.</p>
            </div>
            <form onSubmit={handleSubmit} className="flex gap-0">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter a valid email"
                type="email"
                className="block w-full py-3 px-5 bg-transparent outline-none border-gray-400 border-[1px]"
              />

              <button type="submit" className="pry-cta-button py-4 px-4 w-1/3">
                Subscribe
              </button>
            </form>
            {errors.email && <p className="text-red-600 text-xs font-medium">{errors.email}</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
