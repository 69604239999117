import React from "react";

const Project = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <h2 className="text-2xl font-bold mb-6 sm:text-4xl sm:mb-8">
        Positive Youth Development Program
      </h2>

      <div className="flex items-center gap-3">
        <img
          className="mb-6 object-cover sm:h-[420px] md:w-1/2"
          src={require("../assets/images/hero-image2.png")}
          alt="positive youth development program image"
        />
        <img
          className="mb-6 object-cover hidden sm:h-[420px] md:block md:w-1/2"
          src={require("../assets/images/hero-image3.png")}
          alt="positive youth development program image"
        />
      </div>

      <main className="flex-grow">
        <section className="mb-8">
          <h3 className="text-xl font-semibold md:text-2xl">Youth Literacy Overview</h3>
          <p className="mt-4">
            The foundation’s literacy program aims to empower youth through education, fostering
            independence, dignity, and societal contribution. Many Nigerian youths lack a reading
            culture due to limited opportunities outside the classroom. The program addresses this
            by providing continued literacy activities, following a four-stage framework from basic
            word recognition to advanced comprehension.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-xl font-semibold md:text-2xl">
            Program Design & Implementation Overview
          </h3>
          <p className="mt-4">
            The program includes curriculum development, teacher training, material distribution,
            and student recruitment. Local adaptations ensure relevance, while national frameworks
            guide overall progress and certification. Teachers and mentors play a critical role in
            this process.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-xl font-semibold md:text-2xl">Program Overview</h3>
          <p className="mt-4">
            The Positive Youth Development Program integrates literacy with life skills such as
            computer use and health education. The focus is on increasing the knowledge and skills
            of Nigerian youth (ages 13-19), advancing a youth development network, and promoting
            leadership and competency among participants.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-xl font-semibold md:text-2xl">
            Fundamental Diversity & Target Audience
          </h3>
          <p className="mt-4">
            The program acknowledges diverse needs, including language barriers, prior schooling,
            and living situations. It adapts materials to meet the varying experiences of youth,
            from urban and rural backgrounds to those affected by violence.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-xl font-semibold md:text-2xl">
            Geography, Gender & Responsibilities
          </h3>
          <p className="mt-4">
            Disproportionate illiteracy rates among certain regions and genders are considered, and
            flexible program structures are created to accommodate youth responsibilities like work
            and family.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-xl font-semibold md:text-2xl">Summary</h3>
          <p className="mt-4">
            Youth typically lack formal work experience and need to develop work readiness,
            entrepreneurship, and literacy skills. This program integrates literacy with life skills
            like financial management, technology use, and problem-solving. Addressing health risks,
            peer relationships, and mentoring are also vital. Engaging youth in program design
            ensures relevance to their interests, boosting participation. A strong focus on
            self-confidence, independence, and positive identity development enhances motivation.
            Budgeting involves research and development, start-up, recurring costs, and monitoring.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-xl font-semibold md:text-2xl">
            Literacy Provider Application Process
          </h3>
          <ol className="list-decimal list-inside mt-4">
            <li>Fill out an application.</li>
            <li>Complete a demo lesson.</li>
            <li>Review curriculum materials.</li>
            <li>Teach a mock class.</li>
            <li>Sign the program contract.</li>
          </ol>
        </section>

        <section className="mb-8">
          <h3 className="text-xl font-semibold md:text-2xl">Job Requirements</h3>
          <ul className="list-disc list-inside mt-4">
            <li>Fluency in English (written and spoken).</li>
            <li>3 years of teaching experience.</li>
            <li>Teaching English Abroad Certification.</li>
            <li>High school diploma required; Bachelor’s degree preferred.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h3 className="text-xl font-semibold md:text-2xl">Curriculum Structure</h3>
          <p className="mt-4">
            The curriculum is structured into five sections, taking about 12 hours total, and is
            designed for active participation through various group activities.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-xl font-semibold md:text-2xl">Impact Evaluation</h3>
          <p className="mt-4">
            Evaluation focuses on inputs, outcomes, and community impact, with emphasis on
            cost-effectiveness and literacy's influence on income, health, and education.
          </p>
        </section>
      </main>
    </div>
  );
};

export default Project;
