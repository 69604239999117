import React, { useState } from "react";
import { Link } from "react-router-dom";
import useNavigateAndScroll from "../lib/customHookes/useNavigateAndScroll";
import toast from "react-hot-toast";

import {
  FacebookIcon,
  FooterCallIcon,
  FooterEmailIcon,
  FooterLogo,
  InstagramIcon,
  YoutubeIcon,
} from "../assets/Icons";
import { ContactForm, DonationForm, VolunteerForm } from "./Components";

function Footer() {
  const navigateAndScroll = useNavigateAndScroll();
  const [isContactForm, setIsContactForm] = useState("");
  const [isDonationForm, setIsDonationForm] = useState("");
  const [isVolunteerForm, setIsVolunteerForm] = useState("");

  const comingSoon = () => {
    toast.success("Coming soon!");
  };

  return (
    <footer className="text-[#c9c4c4] tracking-wide text-sm sm:text-[15px] text-opacity-75 bg-gray-950 flex flex-col items-center">
      <div className="w-full max-w-7xl px-5 py-14 space-y-12 sm:px-12 md:space-y-16 md:py-20 2xl:px-0">
        <div className="w-full flex flex-col justify-between gap-12 md:flex-row">
          <div className="flex flex-col justify-between">
            <button onClick={() => navigateAndScroll("/", "home", -200)} className="mb-16">
              <FooterLogo />
            </button>
            <div>
              <div className="text-smx mb-6 opacity-85 space-y-2">
                <div className="flex items-center gap-2">
                  <FooterEmailIcon /> <p>info@christopherandviolet.org</p>
                </div>
                <div className="flex items-center gap-2">
                  <FooterCallIcon /> <p>+120 26817 728</p>
                </div>
              </div>
              <div className="flex opacity-75 items-center gap-3">
                <a
                  href="https://www.instagram.com/christopherandviolet?igsh=azR5ZmhvYmlsbTRu&utm_source=qr"
                  className="border-[2px] border-[#c9c4c4] p-2 rounded-full"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://facebook.com"
                  className="border-[2px] border-[#c9c4c4] p-2 rounded-full"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://youtube.com"
                  className="border-[2px] border-[#c9c4c4] p-2 rounded-full"
                >
                  <YoutubeIcon />
                </a>
              </div>
            </div>
          </div>

          <hr className="border-none bg-gray-300 h-[0.3px] opacity-30 md:hidden" />

          <div className="flex flex-col gap-y-3">
            <p className="text-nowrap text-[#f3f3f3] mb-4 uppercase">quick links</p>

            <Link
              onClick={() => navigateAndScroll("/", "home", -200)}
              to="/"
              className="footer-navs mx-0"
            >
              Home
            </Link>
            <Link
              onClick={() => navigateAndScroll("/about", "about", -200)}
              to="/about"
              className="footer-navs mx-0"
            >
              About Us
            </Link>
            <Link
              onClick={() => navigateAndScroll("/projects", "projects", -200)}
              to="/projects"
              className="footer-navs mx-0"
            >
              Projects
            </Link>
            <button onClick={() => setIsContactForm(!isContactForm)} className="footer-navs mx-0">
              Contact Us
            </button>
            <Link onClick={comingSoon} to="#" className="footer-navs mx-0">
              Admin
            </Link>
          </div>

          <hr className="border-none bg-gray-300 h-[0.3px] opacity-30 md:hidden" />

          <div className="flex flex-col gap-y-3">
            <p className="text-nowrap mb-4 text-[#f3f3f3] uppercase">support us</p>

            <button onClick={() => setIsDonationForm(!isDonationForm)} className="footer-navs mx-0">
              Donate
            </button>
            <button onClick={comingSoon} className="footer-navs mx-0">
              Volunteer
            </button>
            <Link onClick={comingSoon} to="#" className="footer-navs mx-0">
              Corporate Partnership
            </Link>
            <Link onClick={comingSoon} to="#" className="footer-navs mx-0">
              Fundraising Events
            </Link>
            <button onClick={() => setIsDonationForm(!isDonationForm)} className="footer-navs mx-0">
              Sponsorship
            </button>
          </div>

          <hr className="border-none bg-gray-300 h-[0.3px] opacity-30 md:hidden" />

          <div className="flex flex-col gap-y-3">
            <p className="text-nowrap mb-4 text-[#f3f3f3] uppercase">resources</p>

            <Link onClick={comingSoon} to="#" className="footer-navs mx-0">
              Blog
            </Link>
            <Link onClick={comingSoon} to="#" className="footer-navs mx-0">
              Annual Reports
            </Link>
            <Link onClick={comingSoon} to="#" className="footer-navs mx-0">
              Financial Statements
            </Link>
            <Link onClick={comingSoon} to="#" className="footer-navs mx-0">
              News & Press
            </Link>
            <Link onClick={comingSoon} to="#" className="footer-navs mx-0">
              Partners
            </Link>
          </div>
        </div>

        <div className="w-full max-w-7xl mx-auto space-y-8 xl:px-0">
          <hr className="border-none bg-gray-100 h-[0.5px] bg-opacity-25" />

          <p className="text-sm text-center">
            &copy; All rights reserved. Christopher & Violet Foundation.
          </p>
        </div>
      </div>

      {/* Conditional Render Components */}
      {isContactForm && <ContactForm value={isContactForm} setter={setIsContactForm} />}
      {isDonationForm && <DonationForm value={isDonationForm} setter={setIsDonationForm} />}
      {isVolunteerForm && <VolunteerForm value={isVolunteerForm} setter={setIsVolunteerForm} />}
    </footer>
  );
}

export default Footer;
