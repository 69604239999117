import React from "react";
import { programsDATA } from "../assets/data";

function Programs() {
  return (
    <section id="programs" className="bg-gray-500 bg-opacity-5 px-4 py-14 md:py-20">
      <div className="max-w-7xl mx-auto">
        <div className="mb-10 text-center md:mb-14">
          <div
            data-aos="fade-up"
            data-aos-offset="100"
            className="flex items-center justify-center gap-1"
          >
            <hr className="bg-red-900 border-none h-0.5 w-[20px]" />
            <h2 className="font-bold text-lg text-red-900">Our Programs</h2>
            <hr className="bg-red-900 border-none h-0.5 w-[20px]" />
          </div>
          <p
            data-aos="fade-up"
            data-aos-offset="100"
            className="text-gray-900 text-5xl lg:text-7xl"
          >
            Humanity's welfare over personal gains.
          </p>
        </div>

        <div className="w-full grid md:grid-cols-3 gap-2">
          {programsDATA.map(({ icon, imgUrl, title, content }, index) => (
            <div data-aos="fade-up" data-aos-offset="160" key={index} className="relative w-full">
              <img
                src={imgUrl}
                alt="youth engaging in leadership activities"
                className="h-[420px] w-full mx-auto object-cover shadow-md"
              />
              <div className="absolute top-1/2 -translate-y-1/2 z-10 w-full bottom-0x text-[#e9e5e5] bg-sky-600x text-centerx p-8 py-6">
                <div className="mb-4">{icon}</div>
                <h3 className="uppercase text-xl mb-4">{title}</h3>
                <p className="text-sm leading-normal">{content}</p>
              </div>
              <div
                className={`${index == 0 && "bg-red-900 bg-opacity-70"} ${
                  index == 1 && "bg-green-900 bg-opacity-70"
                } ${index == 2 && "bg-yellow-700 bg-opacity-70"}
               absolute top-0 bottom-0 left-0 right-0`}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Programs;
