import useNavigateAndScroll from "../lib/customHookes/useNavigateAndScroll";

function AboutSection() {
  const navigateAndScroll = useNavigateAndScroll();

  return (
    <section
      id="aboutSection"
      className="px-5 py-10 text-gray-950 bg-amber-50 bg-opacity-5 sm:px-8 md:py-20"
    >
      <div
        data-aos="fade-up"
        data-aos-offset="100"
        className="flex flex-col-reverse gap-20 max-w-7xl mx-auto md:flex-row md:items-center md:gap-x-20"
      >
        <img
          data-aos="fade-up"
          data-aos-offset="100"
          className="w-full transform scale-x-[-1] md:w-[45%]"
          src={require("../assets/images/about_img.png")}
          alt="image of a child"
        />

        <div data-aos="fade-up" data-aos-offset="100" className="w-full">
          <div d className="flex items-center">
            <hr className="bg-red-800 border-none h-0.5 w-[20px] mr-1" />
            <h2 className="font-bold text-lg text-red-800">Who We Are</h2>
          </div>
          <p className="text-gray-900 mb-4 text-5xl lg:text-[73px]">
            Our Impact Goes Beyond Immediate Beneficiaries.
          </p>
          <div>
            <p className="mb-4 text-justify">
              The Christopher & Violet Foundation, a 501(c)(3) organization, is driven by a profound
              commitment to support and enhance the lives of children and young adults who find
              themselves alone in the world.
            </p>
            <p className="mb-6 text-justify">
              Our mission is to provide them with a chance—a chance for a brighter future, enriched
              with opportunities, care, and guidance. Rooted in compassion and dedicated to making a
              lasting impact, we strive to be a beacon of hope, empowering the vulnerable to
              overcome adversity and discover their true potential.
            </p>
            <button
              onClick={() => navigateAndScroll("/about", "about", -200)}
              className="pry-cta-button shadow-sm flex items-center gap-2 py-6 px-8 w-fit"
            >
              Learn more
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_134_148)">
                  <path
                    d="M14.3992 7.5L14.7532 7.146L15.1062 7.5L14.7532 7.854L14.3992 7.5ZM14.0452 7.854L10.0452 3.854L10.7532 3.146L14.7532 7.146L14.0452 7.854ZM14.7532 7.854L10.7532 11.854L10.0452 11.146L14.0452 7.146L14.7532 7.854ZM14.3992 8H1.89917V7L14.3992 7V8Z"
                    fill="#F3F3F3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_134_148">
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      transform="matrix(0 -1 -1 0 15.8992 15)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
