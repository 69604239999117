import { CommunityIcon, EducationIcon, MentorIcon } from "./Icons";

export const programsDATA = [
  {
    icon: <EducationIcon />,
    imgUrl: require("../assets/images/program/image1.png"),
    title: "Educational Support",
    content: "Providing scholarships and educational resources to underprivileged youth.",
  },
  {
    icon: <MentorIcon />,
    imgUrl: require("../assets/images/program/image2.png"),
    title: "Crowdfunding",
    content:
      "Your donation enhances our charity efforts. We welcome fundraising from anything you can give.",
  },
  {
    icon: <CommunityIcon />,
    imgUrl: require("../assets/images/program/image3.png"),
    title: "Community Engagement",
    content:
      "Organizing community events and activities to foster a sense of belonging and purpose.",
  },
];

export const testimonialDATA = [
  {
    imageURL: require("../assets/images/testimonials/testimonial1.png"),
    quote:
      "I never thought I’d be able to send my children to school, but The Christopher and Violet Foundation made it possible. Their educational support has transformed our lives, and I’m forever grateful.",
    name: "Chidinma Okeke",
    address: "Awka, Anambra State",
  },
  {
    imageURL: require("../assets/images/testimonials/testimonial2.png"),
    quote:
      "Thanks to Christopher and Violet Foundation, my daughter now has access to quality education. Their scholarship program has given her a brighter future, something I could never have afforded on my own.",
    name: "Janet Nwankwo",
    address: "Enugu, Enugu State",
  },
  {
    imageURL: require("../assets/images/testimonials/testimonial3.png"),
    quote:
      "I struggled to keep my teenage children engaged in positive activities, but the foundation’s youth empowerment programs have changed that. They’ve learned valuable skills and have a sense of direction for their future.",
    name: "Ifeanyi Udeh",
    address: "Umuahia, Abia State",
  },
  {
    imageURL: require("../assets/images/testimonials/testimonial4.png"),
    quote:
      "My nephew was struggling to stay in school, but with the foundation's intervention, he received the educational resources he needed. He is now excelling in his studies and even dreaming of university.",
    name: "Ngozi Eze",
    address: "Owerri, Imo State",
  },
  {
    imageURL: require("../assets/images/testimonials/testimonial5.jpg"),
    quote:
      "The scholarships provided by the foundation changed my son’s life. He now attends university, something I never thought we could afford. We owe it all to their generosity.",
    name: "Chibuzo Nwachukwu",
    address: "Okigwe, Imo State.",
  },
  {
    imageURL: require("../assets/images/testimonials/testimonial6.png"),
    quote:
      "As a young adult, I had little to no idea how to navigate my future, but the foundation’s mentorship program has helped me plan for my career. I’ve learned new skills and now feel more confident about my path ahead.",
    name: "Amarachi Ibe",
    address: "Abakaliki, Ebonyi State",
  },
  {
    imageURL: require("../assets/images/testimonials/testimonial7.png"),
    quote:
      "Through the foundation’s skills acquisition program, I’ve learned tailoring and now run my own business. Christopher and Violet Foundation gave me the tools to succeed and take care of my family.",
    name: "Emeka Obi",
    address: "Nsukka, Enugu State",
  },
];

export const projectsDATA = [
  {
    imgUrl: require("../assets/images/project1.png"),
    title: "Positive Youth Development Program",
    projectID: "project1",
  },
  {
    imgUrl: require("../assets/images/project2.png"),
    title: "Youth Empowerment Development Program",
    projectID: "project2",
  },
  {
    imgUrl: require("../assets/images/project3.png"),
    title: "Youth Healthcare Development Program",
    projectID: "project3",
  },
];
