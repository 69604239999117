import useNavigateAndScroll from "../lib/customHookes/useNavigateAndScroll";
import { projectsDATA } from "../assets/data";

const ProjectsSection = () => {
  const navigateAndScroll = useNavigateAndScroll();

  return (
    <section className="bg-[#001f3f] text-gray-100 py-14 md:py-20">
      <div data-aos="fade-up" data-aos-offset="200">
        <p className="uppercase text-2xl text-center">Our Projects</p>
        <hr className="border-none bg-gray-100 h-[2px] w-[60px] mx-auto my-3 mb-20 md:mb-28" />

        <div className="w-full max-w-7xl mx-auto px-3 mb-16 flex flex-wrap gap-y-8">
          {projectsDATA.map(({ projectID, imgUrl, title }) => (
            <div
              key={projectID}
              xdata-aos="fade-up"
              xdata-aos-offset="150"
              class="relative w-1/2 px-1 lg:w-1/3"
            >
              <img className="h-[320px] w-full object-cover mb-2" src={imgUrl} alt={title} />
              <div className="">
                <p className="text-lg cursor-pointer mb-1">{title}</p>
                <button
                  onClick={() => navigateAndScroll("/projects", "projects", -200)}
                  className="text-sm text-gray-400  hover:underline"
                >
                  Read more ⨠
                </button>
              </div>
              <div
                onClick={() => navigateAndScroll("/projects", "projects", -200)}
                className="absolute -top-1 -bottom-1 -left-1 -right-1 cursor-pointer transition-all duration-500 hover:bg-black hover:bg-opacity-20"
              />
            </div>
          ))}
        </div>

        <button
          onClick={() => navigateAndScroll("/projects", "projects", -200)}
          className="pry-cta-button tracking-wider mx-auto w-fit block py-5 px-6 uppercasex shadow-lg"
        >
          View all projects
        </button>
      </div>
    </section>
  );
};

export default ProjectsSection;
