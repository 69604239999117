import React from "react";

const Founder = () => {
  return (
    <section className="bg-[#001f3f] text-[#f3f3f3] pt-16 shadow-md lg:py-16">
      <div
        data-aos="fade-up"
        data-aos-offset="150"
        className="flex flex-col w-full gap-y-14 gap-x-10 max-w-7xl mx-auto lg:flex-row lg:items-center lg:px-10 xl:gap-x-16 2xl:px-0"
      >
        <div className="text-lg text-center w-[90%] max-w-3xl mx-auto lg:text-left lg:w-1/2">
          <p className="uppercase text-3xl">Meet Our Founder</p>
          <hr className="border-none bg-[#f3f3f3] h-[2px] w-[60px] mx-auto mt-6 mb-16 lg:mx-0" />
          <p className="w-full text-left md:text-justify leading-relaxed pl-6">
            <svg
              className="mb-3 -translate-x-6"
              width="28"
              height="28"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V8.558C12.9999 8.29278 12.8946 8.03843 12.707 7.85089C12.5195 7.66336 12.2652 7.558 11.9999 7.558H10.6119C10.6119 7.20667 10.6326 6.85533 10.6739 6.504C10.7359 6.132 10.8393 5.80133 10.9839 5.512C11.1286 5.22267 11.3149 4.995 11.5429 4.829C11.7696 4.643 12.0589 4.55 12.4109 4.55V3C11.8323 3 11.3256 3.124 10.8909 3.372C10.4593 3.61687 10.0884 3.95595 9.80594 4.364C9.52155 4.81315 9.31215 5.30559 9.18594 5.822C9.05832 6.39174 8.99592 6.97415 8.99994 7.558V11C8.99994 11.2652 9.1053 11.5196 9.29283 11.7071C9.48037 11.8946 9.73472 12 9.99994 12H11.9999ZM5.99994 12C6.26515 12 6.51951 11.8946 6.70704 11.7071C6.89458 11.5196 6.99994 11.2652 6.99994 11V8.558C6.99994 8.29278 6.89458 8.03843 6.70704 7.85089C6.51951 7.66336 6.26515 7.558 5.99994 7.558H4.61194C4.61194 7.20667 4.6326 6.85533 4.67394 6.504C4.7366 6.132 4.83994 5.80133 4.98394 5.512C5.12861 5.22267 5.31494 4.995 5.54294 4.829C5.76961 4.643 6.05894 4.55 6.41094 4.55V3C5.83227 3 5.3256 3.124 4.89094 3.372C4.45928 3.61687 4.08841 3.95595 3.80594 4.364C3.52155 4.81315 3.31215 5.30559 3.18594 5.822C3.05832 6.39174 2.99592 6.97415 2.99994 7.558V11C2.99994 11.2652 3.10529 11.5196 3.29283 11.7071C3.48037 11.8946 3.73472 12 3.99994 12H5.99994Z"
                fill="#f3f3f3"
              />
            </svg>
            The Christopher & Violet Foundation believes every child and young adult deserves a
            chance to thrive, regardless of circumstances. Founded on compassion, empathy, and
            unwavering support, the foundation helps those left alone in the world. Inspired by the
            resilience of children and young adults facing challenges, the foundation is committed
            to providing opportunities, care, and guidance for brighter futures. The mission is to
            create a nurturing environment with educational resources, emotional support, and
            mentorship to break the cycle of adversity. The foundation appreciates the community,
            partners, and volunteers' support in transforming lives and communities. Join us through
            donations, volunteering, or spreading the word to make a profound impact.
            <svg
              className="rotate-180 mt-3 -translate-x-6"
              width="28"
              height="28"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V8.558C12.9999 8.29278 12.8946 8.03843 12.707 7.85089C12.5195 7.66336 12.2652 7.558 11.9999 7.558H10.6119C10.6119 7.20667 10.6326 6.85533 10.6739 6.504C10.7359 6.132 10.8393 5.80133 10.9839 5.512C11.1286 5.22267 11.3149 4.995 11.5429 4.829C11.7696 4.643 12.0589 4.55 12.4109 4.55V3C11.8323 3 11.3256 3.124 10.8909 3.372C10.4593 3.61687 10.0884 3.95595 9.80594 4.364C9.52155 4.81315 9.31215 5.30559 9.18594 5.822C9.05832 6.39174 8.99592 6.97415 8.99994 7.558V11C8.99994 11.2652 9.1053 11.5196 9.29283 11.7071C9.48037 11.8946 9.73472 12 9.99994 12H11.9999ZM5.99994 12C6.26515 12 6.51951 11.8946 6.70704 11.7071C6.89458 11.5196 6.99994 11.2652 6.99994 11V8.558C6.99994 8.29278 6.89458 8.03843 6.70704 7.85089C6.51951 7.66336 6.26515 7.558 5.99994 7.558H4.61194C4.61194 7.20667 4.6326 6.85533 4.67394 6.504C4.7366 6.132 4.83994 5.80133 4.98394 5.512C5.12861 5.22267 5.31494 4.995 5.54294 4.829C5.76961 4.643 6.05894 4.55 6.41094 4.55V3C5.83227 3 5.3256 3.124 4.89094 3.372C4.45928 3.61687 4.08841 3.95595 3.80594 4.364C3.52155 4.81315 3.31215 5.30559 3.18594 5.822C3.05832 6.39174 2.99592 6.97415 2.99994 7.558V11C2.99994 11.2652 3.10529 11.5196 3.29283 11.7071C3.48037 11.8946 3.73472 12 3.99994 12H5.99994Z"
                fill="#f3f3f3"
              />
            </svg>
          </p>
          <div className="mt-6">
            <p className="text-xl">Sochima Ekemezie</p>
            <p className="text-sm text-gray-100">Founder, Christopher & Violet Foundation.</p>
          </div>
        </div>

        <img
          data-aos="fade-up"
          data-aos-offset="100"
          src={require("../assets/images/founder.jpg")}
          alt="Sochima Ekemezie. Founder, Christopher & Violet Foundation."
          className="h-[520px] w-full max-w-[820px] mx-auto object-cover object-center lg:mx-0 lg:h-[620px] lg:w-1/2"
        />
      </div>
    </section>
  );
};

export default Founder;
