import { useState } from "react";
import { DonationForm } from "./Components";

const CtaSection = () => {
  const [isDonationForm, setIsDonationForm] = useState("");

  return (
    <section id="ctasection" className="cta-section bg-fixed bg-cover bg-center">
      <div className="text-center text-[#e9e5e5] justify-center bg-black bg-opacity-50 backdrop-blur-[2px] px-1 py-20 md:py-28">
        <div>
          <div className="mb-14 md:mb-16">
            <p className="text-[2.9rem]x text-5xl leading-[0.9] mb-4 max-w-5xl mx-auto md:text-8xl">
              Be a part of creating a lasting impact
            </p>
            <p className="text-sm md:text-base">
              Our fingerprints on the lives we touch never fade.
            </p>
          </div>
          <button
            onClick={() => setIsDonationForm(!isDonationForm)}
            className="border-yellow-600 border-[2px] rounded-full px-8 py-6 transition-all duration-500 hover:bg-yellow-600 hover:bg-opacity-20"
          >
            Donate Today
          </button>
        </div>
      </div>
      {isDonationForm && <DonationForm value={isDonationForm} setter={setIsDonationForm} />}
    </section>
  );
};

export default CtaSection;
