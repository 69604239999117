import Hero from "../Hero";
import Programs from "../Programs";
import AboutSection from "../AboutSection";
import Testimonials from "../Testimonials";
import CtaSection from "../CtaSection";
import Newsletter from "../Newsletter";
import ProjectsSection from "../ProjectsSection";

const Home = () => {
  return (
    <main id="home" className="text-[#0d1c29] bg-[#f9f9f9">
      <Hero />
      <AboutSection />
      <Programs />
      <ProjectsSection />
      <Testimonials />
      <CtaSection />
      <Newsletter />
    </main>
  );
};

export default Home;
