import React, { useState } from "react";
import { useLockBodyScroll } from "@custom-react-hooks/use-lock-body-scroll";

import { HeaderMenuIcon } from "../assets/Icons";
import { NavLink } from "react-router-dom";
import useNavigateAndScroll from "../lib/customHookes/useNavigateAndScroll";
import { Logo } from "../assets/Icons";
import { ContactForm, DonationForm } from "./Components";

function Header() {
  const [isMobile, setIsMobile] = useState(null);
  const [isContactForm, setIsContactForm] = useState("");
  const [isDonationForm, setIsDonationForm] = useState("");

  const navigateAndScroll = useNavigateAndScroll();
  useLockBodyScroll(isMobile);

  return (
    <header className="w-full absolute z-40 text-[#f3f3f3] tracking-wide">
      <div className="py-7 flex items-center justify-between max-w-[96%] mx-auto md:px-8 xl:max-w-[90vw]">
        <button onClick={() => navigateAndScroll("/", "home", -200)}>
          <Logo />
        </button>

        <nav className="hidden py-6 gap-20 md:items-center lg:flex xl:gap-24">
          <NavLink
            onClick={() => navigateAndScroll("/", "home", -200)}
            to="/"
            className="header-navs"
          >
            Home
          </NavLink>
          <NavLink
            onClick={() => navigateAndScroll("/about", "about", -200)}
            to="/about"
            className="header-navs"
          >
            About Us
          </NavLink>
          <NavLink
            onClick={() => navigateAndScroll("/projects", "projects", -200)}
            to="/projects"
            className="header-navs"
          >
            Projects
          </NavLink>

          <button onClick={() => setIsContactForm(!isContactForm)} className="header-navs">
            Contact Us
          </button>
          <button onClick={() => setIsDonationForm(!isDonationForm)} className="header-navs">
            Donate
          </button>
        </nav>

        <div className="flex items-center gap-x-3 lg:hidden">
          <div className="z-[100] lg:hidden">
            <HeaderMenuIcon value={isMobile} setter={setIsMobile} />
          </div>
        </div>
      </div>

      <nav
        onClick={() => setIsMobile(!isMobile)}
        className={`${
          isMobile ? "translate-y-0 opacity-100" : "-translate-y-5 opacity-0 pointer-events-none"
        }  absolute top-0 z-30 w-full h-[100dvh] flex flex-col justify-center gap-y-10 text-base bg-[#001f3f] text-[#e9e5e5] transition-all duration-700 ease-in-out >> lg:hidden`}
      >
        <NavLink
          onClick={() => navigateAndScroll("/", "home", -200)}
          to="/"
          className="header-navs"
        >
          Home
        </NavLink>
        <NavLink
          onClick={() => navigateAndScroll("/about", "about", -200)}
          to="/about"
          className="header-navs"
        >
          About Us
        </NavLink>
        <NavLink
          onClick={() => navigateAndScroll("/projects", "projects", -200)}
          to="/projects"
          className="header-navs"
        >
          Projects
        </NavLink>

        <button onClick={() => setIsContactForm(!isContactForm)} className="header-navs">
          Contact Us
        </button>
        <button onClick={() => setIsDonationForm(!isDonationForm)} className="header-navs">
          Donate
        </button>

        <div className="absolute z-40 bg-black bg-opacity-50 h-dvh -bottom-full top-full left-0 right-0" />
      </nav>

      {isDonationForm && <DonationForm value={isDonationForm} setter={setIsDonationForm} />}
      {isContactForm && <ContactForm value={isContactForm} setter={setIsContactForm} />}
    </header>
  );
}

export default Header;
