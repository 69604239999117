import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./ui/Header";
import Home from "./ui/pages/Home";
import Footer from "./ui/Footer";
import About from "./ui/pages/About";
import Projects from "./ui/pages/Projects";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1200, // Animation duration in milliseconds
      easing: "ease-out", // Animation easing
      // offset: 200, // Offset to trigger the animation
      // delay: 10, // Delay before animation starts
    });
  }, []);

  return (
    <BrowserRouter>
      <Toaster />
      {/* <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-gray-100 bg-opacity-5 pointer-events-none" /> */}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
