import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";

const useNavigateAndScroll = () => {
  const navigate = useNavigate();

  const navigateAndScroll = (path, elementId, offset = 0) => {
    navigate(path);
    // Use a timeout to ensure the new route has loaded before scrolling
    setTimeout(() => {
      scroller.scrollTo(elementId, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: offset,
      });
    }, 100);
  };

  return navigateAndScroll;
};

export default useNavigateAndScroll;
